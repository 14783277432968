import "../styles/globals.css";
import type { AppProps } from "next/app";
import { ApolloProvider } from "@apollo/client";
import client from "lib/apollo";
import { Inter } from "next/font/google";
import ChatBubble from "@components/ChatBubble";

const inter = Inter({
  subsets: ["latin"],
  weight: ["400", "500", "600", "700", "800", "900"],
});
import { ClerkProvider } from "@clerk/nextjs";
import { Toaster } from "react-hot-toast";
import { trpc } from "@utils/trpc";
import { Page } from "types/page";
import Head from "next/head";
import Script from "next/script";

type Props = AppProps & {
  Component: Page;
};

// /marketplace

function MyApp({ Component, pageProps }: Props) {
  const getLayout = Component.getLayout || ((page) => page);
  return (
    <>
      <ClerkProvider {...pageProps}>
        <ApolloProvider client={client}>
          {getLayout(<Component {...pageProps} />)}
          <Toaster containerClassName="z-[999]" />
        </ApolloProvider>
        <Script
          id="hs-script-loader"
          src="//js.hs-scripts.com/24344991.js"
          strategy="afterInteractive"
          async
          defer
        />
      </ClerkProvider>
      <ChatBubble />
    </>
  );
}

export default trpc.withTRPC(MyApp);
